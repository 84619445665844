import React from 'react';
import Loader from "./Loader";

const withSuspence = (Component) => {
  return (props) => {
    return (
      <React.Suspense fallback={<Loader/>}>
        <Component {...props}/>
      </React.Suspense>
    )
  }
}

export default withSuspence