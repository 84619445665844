import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import store from "./redux/store";
import App from "./App";
import  {HelmetProvider } from 'react-helmet-async';

if (process.env.NODE_ENV === 'production') {
  const GOOGLE_SEARCH_KEY = process.env.REACT_APP_GOOGLE_SEARCH_GTAG_KEY

  var script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true
  script.src = 'https://www.googletagmanager.com/gtag/js?id=' + GOOGLE_SEARCH_KEY;
  document.head.append(script)

  script = document.createElement('script');
  script.type = 'text/javascript';
  script.text = "window.dataLayer = window.dataLayer || [];\n" +
    "\n" +
    "      function gtag() {\n" +
    "        dataLayer.push(arguments);\n" +
    "      }\n" +
    "\n" +
    "      gtag('js', new Date());\n" +
    "\n" +
    "      gtag('config', '" + GOOGLE_SEARCH_KEY + "');"
  document.head.append(script)
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
