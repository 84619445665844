const initialState = {
  partners: [
    {
      image: '/img/schoeb_2020.jpg',
      surname: 'Schoeb',
      name: 'Alexis',
      position: 'Partner'
    },
    {
      image: '/img/baumgartner_2020.jpg',
      surname: 'Baumgartner',
      name: 'Marc',
      position: 'Partner'
    },
    {
      image: '/img/totaro_2020.jpg',
      surname: 'Totaro',
      name: 'Micael',
      position: 'Associate'
    }
  ]
}

const partnersReducer = (state = initialState) => {
  return state
}

export default partnersReducer