import React from 'react';
import Body from "./Body";
import Footer from "./Footer";
import MainMenuContainer from "./main-menu/MainMenuContainer";
import HeaderContainer from "./header/HeaderContainer";
import {Helmet} from 'react-helmet-async';

const Root = (props) => {
  return (
    <>
      <Helmet>
        <meta name="description" content="A BUSINESS AND SPORTS LAW FIRM"/>
        <meta name="og:title" content="SCHOEB BAUMGARTNER | A BUSINESS AND SPORTS LAW FIRM"/>
        <meta name="og:site_name" content="SCHOEB BAUMGARTNER | A BUSINESS AND SPORTS LAW FIRM"/>
        <meta name="og:type" content="website"/>
        <meta name="og:image" content="/img/logo_header.jpg"/>
        <meta name="og:description" content="SCHOEB BAUMGARTNER | A BUSINESS AND SPORTS LAW FIRM"/>
        <title>SCHOEB BAUMGARTNER | A BUSINESS AND SPORTS LAW FIRM</title>
      </Helmet>

      <HeaderContainer/>
      <Body/>
      <Footer/>
      {props.isMenuOpened && <MainMenuContainer/>}
    </>
  );
}

export default Root
