import Header from "./Header";
import {openMainMenuActionCreator} from "../../redux/reducers/MainMenuReducer";
import {connect} from "react-redux";

const mapStateToProps = (state) => {
    return {
        isMenuOpened: state.mainMenu.isMenuOpened
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onOpenIconClick: () => { dispatch(openMainMenuActionCreator()) }
    }
}

const HeaderContainer = connect(mapStateToProps, mapDispatchToProps)(Header)

export default HeaderContainer
