import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    SwissLegalApiKey: process.env.REACT_APP_API_KEY
  }
})

export const PostsAPI = {
  getPosts(page, count) {
    return instance.get(`posts/${page}/${count}`)
  }
}