import React from 'react';
import {NavLink} from "react-router-dom";
import logo_header from '../../img/logo_header.jpg'

const Header = (props) => {

    const onMenuIconClick = () => {
        props.onOpenIconClick()
    }

    return (
        <div className="header">
            <div className="page_wrapper">
                <div className="header_content">
                    <div>
                        <NavLink to="/" title="SCHOEB BAUMGARTNER | A BUSINESS AND SPORTS LAW FIRM">
                            <img src={logo_header} alt="Schoeb Baumgartner"/>
                        </NavLink>
                    </div>
                    <div className="menu_container">
                        <div className="menu_label">MENU</div>
                        <div>
                            <div className={`bars ${props.isMenuOpened && 'invisible'}`} onClick={onMenuIconClick}>
                                <div/>
                                <div/>
                                <div/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header
