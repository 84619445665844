import React from 'react';
import {NavLink} from "react-router-dom";

const MainMenu = (props) => {

    const closeIconClick = () => {
        props.onCloseIconClick()
    }

    return (
        <nav className="popup_main_menu fade-in">
            <div className="page_wrapper">
                <div className="close" onClick={closeIconClick}/>
            </div>

            <div className="menu align_vertical">
                <ul>
                    <li><NavLink to="/firm" onClick={ closeIconClick }>THE FIRM</NavLink></li>
                    <li><NavLink to="/sports" onClick={ closeIconClick }>SPORTS LAW</NavLink></li>
                    <li><NavLink to="/business" onClick={ closeIconClick }>BUSINESS LAW</NavLink></li>
                    <li><NavLink to="/international" onClick={ closeIconClick }>INTERNATIONAL<br/>ARBITRATION</NavLink></li>
                    <li><NavLink to="/news" onClick={ closeIconClick }>NEWS / CASES</NavLink></li>
                    <li><NavLink to="/contact" onClick={ closeIconClick }>CONTACT US</NavLink></li>
                </ul>
            </div>
        </nav>
    );
}

export default MainMenu
