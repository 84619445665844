import {PostsAPI} from "../../api/api";

const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'
const SAVE_POSTS = 'SAVE_POSTS'

const initialState = {
  pages: new Map(),
  pageCount: 4,
  totalCount: 0,
  currentPage: 1
}

const postsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_PAGE:
      return {...state, currentPage: action.page}
    case SAVE_POSTS:
      const newState = {...state, totalCount: action.count}
      newState.pages = new Map(state.pages)
      newState.pages.set(action.currentPage, action.data)
      return newState
    default:
      return state
  }
}

export default postsReducer
export const setCurrentPageActionCreator = (page) => {
  return {type: SET_CURRENT_PAGE, page}
}
export const setPostsDataActionCreator = (currentPage, data, count) => {
  return {type: SAVE_POSTS, currentPage, data, count}
}

export const getPostsThunkCreator = (currentPage, pageCount = initialState.pageCount) => {
  return (dispatch) => {
    return PostsAPI.getPosts(currentPage, pageCount)
      .then((response) => {
        dispatch(setPostsDataActionCreator(currentPage, response.data.posts, response.data.total_count))
        return response
      });
  }
}