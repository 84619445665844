const MAIN_MENU_OPEN = 'MAIN_MENU_OPEN'
const MAIN_MENU_CLOSE = 'MAIN_MENU_CLOSE'

const initialState = {
    isMenuOpened: false
}

const mainMenuReducer = (state = initialState, action) => {
    switch (action.type) {
        case MAIN_MENU_OPEN:
            return {...state, isMenuOpened: true}
        case MAIN_MENU_CLOSE:
            return {...state, isMenuOpened: false}
        default:
            return state
    }
}

export default mainMenuReducer
export const openMainMenuActionCreator = () => { return {type: MAIN_MENU_OPEN} }
export const closeMainMenuActionCreator = () => { return {type: MAIN_MENU_CLOSE} }
