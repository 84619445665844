import React from 'react';
import logo_footer from '../img/logo_footer.png'

const Footer = () => {

    return (
        <footer className="footer">
            <div className="page_wrapper">
                <div className="footer_container">
                    <div><img src={logo_footer} alt="Schoeb Baumgartner"/></div>
                    <div className="f_center">A BUSINESS AND SPORTS LAW FIRM</div>
                    <div className="f_right">Copyright<sup>&copy;</sup> Schoeb Baumgartner Avocats {new Date().getFullYear()}</div>
                </div>
            </div>
        </footer>
    );
}

export default Footer
