import React from 'react';
import {Route, Switch} from "react-router-dom";
import withSuspence from "../helpers/withSuspense";

const NotFound404 = React.lazy(() => import('./NotFound404'));
const Home = React.lazy(() => import('./home/Home'));
const Sports = React.lazy(() => import('./Sports'));
const FirmRouter = React.lazy(() => import('./firm/FirmRouter'));
const Business = React.lazy(() => import('./Business'));
const International = React.lazy(() => import('./International'));
const News = React.lazy(() => import('./news/News'));
const Contact = React.lazy(() => import('./Contact'));

const Body = () => {
  return (
    <Switch>
      <Route exact path='/' component={withSuspence(Home)}/>
      <Route path='/firm' component={withSuspence(FirmRouter)}/>
      <Route exact path='/sports' component={withSuspence(Sports)}/>
      <Route exact path='/business' component={withSuspence(Business)}/>
      <Route exact path='/international' component={withSuspence(International)}/>
      <Route exact path='/news' component={withSuspence(News)}/>
      <Route exact path='/contact' component={withSuspence(Contact)}/>
      <Route component={withSuspence(NotFound404)}/>
    </Switch>
  );
}

export default Body
