import {applyMiddleware, combineReducers, createStore} from "redux";
import mainMenuReducer from "./reducers/MainMenuReducer";
import partnersReducer from "./reducers/PartnersReducer";
import postsReducer from "./reducers/PostsReducer";
import thunk from "redux-thunk";

const reducers = combineReducers({
  mainMenu: mainMenuReducer,
  firm: partnersReducer,
  posts: postsReducer
})

const store = createStore(reducers, applyMiddleware(thunk))

export default store
