import {connect} from "react-redux";
import MainMenu from "./MainMenu";
import {closeMainMenuActionCreator} from "../../redux/reducers/MainMenuReducer";

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        onCloseIconClick: () => {dispatch(closeMainMenuActionCreator())}
    }
}

const MainMenuContainer = connect(mapStateToProps, mapDispatchToProps)(MainMenu)

export default MainMenuContainer
