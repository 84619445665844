import {connect} from "react-redux";
import Root from "./Root";

const mapStateToProps = (state) => {
    return {
        isMenuOpened: state.mainMenu.isMenuOpened
    }
}

const mapDispatchToProps = () => {
    return {}
}

const RootContainer = connect(mapStateToProps, mapDispatchToProps)(Root)

export default RootContainer
